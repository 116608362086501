
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import moment from 'moment';

import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS }
    from '@/modules/price-history/rates-price-history-all.service';

import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import type Day from '@/modules/common/types/day.type';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import Currency from '@/modules/common/components/currency.vue';
import RatesDocumentItemAllModel from '@/modules/rates/models/rates-document-item-all.model';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS } from '../../rates-price-history-common.service';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

export interface TableData {
    id: string;
    name: string;
    price: number;
    diff: number;
    isBasic?: boolean;
    myHotel?: boolean;
    borderColor?: string;
    priceType?: string;
    rank: number | null;
    link?: string;
    pax?: number[] | null;
}

@Component({
    filters: { PercentFilter, PriceFilter },
    components: { Currency },
})
export default class RatesPriceHistoryTableAll extends Vue {
    @inject(RatesPriceHistoryAllServiceS) private ratesPriceHistoryAll!: RatesPriceHistoryAllService;
    @inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @inject(RatesAllServiceS) private ratesAllService!: RatesAllService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    @Prop({ type: String, required: true })
    private priceShown!: PRICE_SHOWN;

    @Prop({ type: Object, default: () => ({}) })
    private ratesSettings!: RatesSettingsModel;

    @Prop({ type: Object, default: () => ({}) })
    hiddenGraphs!: { [k: string]: boolean };

    @Prop({ type: Boolean })
    isLoading!: boolean;

    private get chartColors() {
        return this.userSettingsService.chartColors || [];
    }

    get currency() {
        return this.ratesPriceHistoryCommonService.currency;
    }

    get isChainPage() {
        return (this.$route.name!.includes('chain') && !this.$route.name!.includes('.hotel'))
            || (this.$route.name!.includes('cluster') && !this.$route.name!.includes('.hotel'));
    }

    get isPriceLowest() {
        return this.ratesSettings.priceType === PRICE_TYPE.LOWEST;
    }

    get day(): Day {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    get historyDate() {
        const { selectedTrendDate } = this.ratesPriceHistoryCommonService;
        return selectedTrendDate ? moment(selectedTrendDate).format('DD.MM') : '---';
    }

    get scanDate() {
        const { selectedScanDate } = this.ratesPriceHistoryAll;
        return selectedScanDate ? moment(selectedScanDate).format('DD.MM') : '---';
    }

    get days() {
        const { dayIndex } = this.ratesPriceHistoryCommonService;
        return !dayIndex ? this.$tc('lastUpdate') : `-${this.$t('numDays', [dayIndex])}`;
    }

    get bookingBasicIcon() {
        return BOOKING_BASIC_ICON;
    }

    get tableData() {
        const { providers } = this.ratesPriceHistoryAll;
        if (!providers) {
            return [];
        }

        const tablePriceHistoryData: TableData[] = providers.map((provider: string, index: number) => {
            const { lastScanDate } = this.ratesPriceHistoryCommonService;
            const { dayIndex } = this.ratesPriceHistoryCommonService;

            if (lastScanDate === null) {
                return {} as TableData;
            }

            const providerItem = this.ratesPriceHistoryAll
                .getSuitableProviderByDay(dayIndex);

            if (!providerItem) {
                return {
                    id: provider,
                    name: this.providersService.getProviderLabel(provider)
                        || this.$t(`compsetRate.${provider}`),

                    price: -1,
                    diff: -1,
                    borderColor: provider === 'average'
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.chartColors[index],
                } as TableData;
            }
            if (!providerItem[provider]) {
                return {
                    id: provider,
                    name: this.providersService.getProviderLabel(provider)
                        || this.$t(`compsetRate.${provider}`),

                    price: -1,
                    diff: -1,
                    borderColor: provider === 'average'
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.chartColors[index],
                } as TableData;
            }

            const averagePrice = this.averagePrice(providerItem);
            const price = Math.round(this.ratesCommonService.switchPrice(providerItem[provider].room, this.priceShown)!);
            const pax = !dayIndex ? providerItem.pax : null;
            const isPriceValid = price >= 1;

            const priceType = isPriceValid
                ? providerItem[provider].room?.priceType
                : undefined;

            const link = !dayIndex
                ? this.ratesCommonService
                    .getHotelLink(this.day, provider, this.ratesPriceHistoryCommonService.rateDocuments?.main)
                : '';

            const isBasic = provider === 'booking_basic' && providerItem[provider].room?.isBasic;

            return {
                id: provider,
                name: this.providersService.getProviderLabel(provider)
                    || this.$t(`compsetRate.${provider}`),
                price: (provider !== 'average') ? price : averagePrice,
                diff: (provider !== 'average')
                    ? this.priceDiff(price, averagePrice)
                    : -1,
                borderColor: provider === 'average'
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : this.chartColors[index],
                myHotel: provider === 'average',
                priceType,
                rank: null,
                link: price >= 0 ? link || '' : '',
                isBasic,
                pax,
            } as TableData;
        });

        let rank = 0;

        return tablePriceHistoryData
            .sort((a, b) => a.price - b.price)
            .map(item => {
                if (item.id !== 'average' && item.price > 0) {
                    rank++;
                    return { ...item, rank };
                }

                return item;
            })
            .reverse();
    }

    private averagePrice(items: {[provider: string]: RatesDocumentItemAllModel}) {
        if (!items.average) {
            return 0;
        }

        const price = this.ratesCommonService.switchPrice(items.average.room, this.priceShown)!;

        return Number.isNaN(price) ? 0 : price;
    }

    private priceDiff(myPrice: number, comparePrice: number) {
        if (myPrice === 0 || myPrice === -1) return '';
        return this.ratesAllService.priceDiff(myPrice, comparePrice);
    }

    toggleGraph(hotel: TableData) {
        this.$emit('toggle-hotel', hotel);
    }
}
