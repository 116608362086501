export enum ColumnKey {
    TYPE,
    COLOR,
    NAME,
    RANK,
    PRICE,
    PRICE_COMPARE_1,
    PRICE_COMPARE_2,
    ROOM_NAME,
    PROVIDERS,
    DIFF,
    PRICE_TYPE,
    BASIC,
}
