var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.tableData)?_c('div',{staticClass:"table"},[_c('p',{staticClass:"scanned-at"},[_vm._v(" "+_vm._s(_vm.$t('titles.scannedAt'))+" "+_vm._s(_vm.historyDate)+" ")]),_c('div',{staticClass:"table__column name"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('titles.provider')))]),_vm._l((_vm.tableData),function(item,i){return _c('div',{key:i + 'name',class:{
                'table__cell': true,
                'table__cell--main': item.myHotel,
            },on:{"click":function($event){return _vm.toggleGraph(item)}}},[_c('div',{class:{
                    'graph-legend': true,
                    'graph-legend--hidden': _vm.hiddenGraphs[item.id],
                },style:({
                    '--indicator-color': item.borderColor,
                })}),_c(item.link ? 'a' : 'p',{tag:"component",class:{
                    'hotel-name__value': true,
                    'hotel-name__value--link': item.link,
                },attrs:{"title":item.name,"href":item.link,"target":"_blank"},domProps:{"textContent":_vm._s(item.name)}}),(!!item.link)?_c('i',{staticClass:"icon-link"}):_vm._e()],1)})],2),_c('div',{staticClass:"table__column rank"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('titles.rank')))]),_vm._l((_vm.tableData),function(item,i){return _c('div',{key:i + 'rank',class:{
                'table__cell': true,
                'table__cell--main': item.myHotel,
            },domProps:{"textContent":_vm._s(item.rank || '---')}})})],2),(_vm.isPriceLowest)?_c('div',{staticClass:"table__column price-type"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('titles.priceType')))]),_vm._l((_vm.tableData),function(item,i){return _c('div',{key:i + 'rank',class:{
                'table__cell': true,
                'table__cell--main': item.myHotel,
            },domProps:{"textContent":_vm._s(item.priceType ? _vm.$tc(`price.${item.priceType}`) : '---')}})})],2):_vm._e(),_c('div',{staticClass:"table__column basic"},[_c('p',{staticClass:"title"}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'price',class:{
                'table__cell basic__value': true,
            }},[(hotel.isBasic)?_c('img',{staticClass:"basic__icon",attrs:{"src":_vm.bookingBasicIcon}}):_vm._e()])})],2),_c('div',{staticClass:"table__column price"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('titles.price')))]),_vm._l((_vm.tableData),function(item,i){return _c('div',{key:i + 'price',class:{
                'table__cell': true,
                'table__cell--main' : item.myHotel,
            }},[(item.price !== -1 && item.price !== 0)?_c('p',[_c('Currency',{attrs:{"currency":_vm.currency}}),_vm._v(" "+_vm._s(_vm._f("PriceFilter")(item.price))+" ")],1):(item.price === 0)?_c('p',{attrs:{"title":item.pax ? _vm.$tc('rates.paxAvailable', 0, [item.pax]) : undefined}},[_vm._v(" "+_vm._s(!item.pax ? _vm.$t('soldOut') : _vm.$tc('nog', 0, [item.pax]))+" ")]):_c('p',[_vm._v("---")])])})],2),_c('div',{staticClass:"table__column room-type"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('titles.diffper')))]),_vm._l((_vm.tableData),function(item,i){return _c('div',{key:i + 'roomType',class:{
                'table__cell': true,
                'table__cell--main': item.myHotel,
            }},[((item.diff || item.diff === 0) && item.diff !== -1)?_c('p',[_vm._v(" "+_vm._s(_vm._f("PercentFilter")(item.diff))+"% ")]):_c('p',[_vm._v("---")])])})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }