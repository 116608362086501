
import { Component, Vue, Prop } from 'vue-property-decorator';
import type { TrendsDocument } from '../../types';
import { ColumnKey } from '../../constants';

interface TableRow {
    id: string;
    [ColumnKey.NAME]: string;
    [ColumnKey.PRICE]: number | null;
    [ColumnKey.PRICE_TYPE]?: string;
    [ColumnKey.ROOM_NAME]: string;
    myHotel: boolean;
    isCompset: boolean;
    providers: string[] | null;
    link?: string;
    rank?: number;
    borderColor?: string;
    isBasic?: boolean;
    isNetCalc?: boolean;
    losRestriction?: boolean | number | null;
    screenshot?: string;
    intradayValue?: number | null;
    specialIntraday?: string | null;
    specialDateTitle?: string | null;
    analysisData?: {
        price: number | null;
        roomName?: string | null;
        isBasic?: boolean;
        losRestriction?: boolean | number | null;
    }[];
}

@Component
export default class Table extends Vue {
    @Prop({ type: Object })
    trendsDocument!: TrendsDocument;

    @Prop({ type: Number })
    trendIndex!: number;

    @Prop({ type: Array })
    entities!: string[];

    @Prop({ type: Object })
    columns!: Record<ColumnKey, {
        label: string;
    }>;

    get tableData() {
        if (this.isNoValidData) {
            return null;
        }

        const { data } = this.trendsDocument.trendData[this.trendIndex];
        const { names } = this.trendsDocument;

        // return {
        //                 ...acc,
        //                 id: String(hotelId),
        //                 name,
        //                 price,
        //                 priceType: (price && price > 0) ? room.priceType : '',
        //                 roomName: (price && price > 0) ? room.roomName : '---',
        //                 myHotel: hotelId === this.ratesPriceHistoryCommonService.compset?.ownerHotelId,
        //                 isCompset: false,
        //                 link: !this.dayIndex ? this.ratesCommonService.getHotelLink(this.day, hotelId, this.mainRateDocument) : '',
        //                 borderColor: hotelColor,
        //                 isBasic: room.isBasic,
        //                 isNetCalc: room.isNetCalc && this.priceShown === PRICE_SHOWN.NET,
        //                 losRestriction: hotel.losRestriction,
        //                 screenshot: this.ratesCommonService.getScreenshot(this.day, hotelId, this.mainRateDocument),
        //                 intradayValue: this.hasIntraday
        //                     ? this.ratesService.intradayByHotelId(this.day, hotelId, this.priceShown)
        //                     : null,
        //                 specialIntraday: room.specialDateName,
        //                 specialDateTitle: room.specialDateName
        //                     ? `${this.$tc('titles.specdate')}: ${room.specialDateName}`
        //                     : undefined,
        //                 providers: this.ratesService
        //                     .getRoomProviders(this.day, hotelId, this.priceShown),
        //             } as TableData;
        //         }

        //         return {
        //             ...acc,
        //             analysisData: [
        //                 ...(acc.analysisData || []),
        //                 {
        //                     price,
        //                     isBasic: room.isBasic,
        //                     losRestriction: hotel.losRestriction,
        //                     roomName: room.roomName,
        //                 },
        //             ],
        //         };
        //     }, {} as TableData)

        const formatedData = this.entities.map(entityId => {
            const entityData = data[entityId];

            const name = names[entityId] || entityId;

            if (!entityData) {
                // [TODO] early return with NA/No data
                return {
                    id: entityId,
                    [ColumnKey.NAME]: name,
                } as TableRow;
            }

            return {
                id: entityId,
                [ColumnKey.NAME]: name,
            } as TableRow;
        });

        return formatedData;
    }

    get isNoValidData() {
        return !this.trendsDocument?.trendData || !this.trendsDocument.trendData[this.trendIndex]?.data || !this.entities;
    }
}
