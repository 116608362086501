
import { Component, Vue, Prop } from 'vue-property-decorator';
import type { TrendFilters } from '../../types';

@Component
export default class Filters extends Vue {
    @Prop({ type: Object })
    filters!: TrendFilters | null;

    get formatedFilters() {
        if (!this.filters) {
            return [];
        }

        const result = [] as string[][];

        const {
            compset, priceType, provider, los, pos, numberOfGuests, roomType, mealType,
        } = this.filters;

        const compsetValue = compset ? `${compset.name} (${this.$tc(`compset.${compset.type}`)})` : '';
        result.push([this.$tc('titles.compset'), compsetValue]);
        const priceTypeValue = priceType ? this.$tc(`price.${priceType}`) : '';
        result.push([this.$tc('titles.price'), priceTypeValue]);
        result.push([this.$tc('titles.provider'), provider]);
        result.push([this.$tc('titles.los'), String(los)]);
        result.push([this.$tc('titles.pos'), pos]);
        result.push([this.$tc('titles.numberOfGuests'), String(numberOfGuests)]);
        const roomTypeValue = this.$te(roomType) ? this.$tc(roomType) : roomType;
        result.push([this.$tc('titles.roomType'), roomTypeValue]);
        const mealTypeValue = this.$te(mealType) ? this.$tc(mealType) : mealType;
        result.push([this.$tc('titles.mealType'), mealTypeValue]);

        return result;
    }
}
