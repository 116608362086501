
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type Day from '@/modules/common/types/day.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CommonDayPopup from './common-day-popup/index.vue';
import RatesService, { RatesServiceS } from '../../rates/rates.service';
import RatesDocumentModel from '../../rates/models/rates-document.model';
import RatesDocumentAllModel from '../../rates/models/rates-document-all.model';
import type { RateTrendsService } from '../types';
import DayPopup from './day-popup-new/index.vue';
import { ColumnKey } from '../constants';

@Component({
    components: { CommonDayPopup, DayPopup },
})
export default class HotelRatesDayPopup extends Vue {
    /** This component is only for regular hotel rates page popup */

    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.HotelRateTrendsService) private rateTrendsService!: RateTrendsService;

    @Prop({ type: Number })
    day!: Day;

    mounted() {
        // For the new trends logic
        const { year, month } = this.documentFiltersService;
        this.rateTrendsService.date = new Date(year, month, parseInt(this.$route.params.day, 10));
        this.rateTrendsService.localPriceShown = this.documentFiltersService.priceShown;
    }

    destroyed() {
        // For the new trends logic
        this.rateTrendsService.date = null;
        this.rateTrendsService.localPriceShown = this.documentFiltersService.priceShown;
    }

    get tableColumns() {
        return {
            [ColumnKey.TYPE]: {
                label: 'titles.type',
                width: '5%',
            },
            [ColumnKey.COLOR]: {
                label: '',
                width: '4%',
            },
            [ColumnKey.NAME]: {
                label: 'titles.name',
                width: '45%',
            },
            [ColumnKey.RANK]: {
                label: 'titles.rank',
                width: '8%',
            },
            [ColumnKey.BASIC]: {
                label: '',
                width: '5%',
            },
            [ColumnKey.PRICE]: {
                label: 'titles.price',
                width: '13%',
            },
            [ColumnKey.ROOM_NAME]: {
                label: 'titles.roomName',
                width: '20%',
            },
        };
    }

    get tooltipColumns() {
        return {
            [ColumnKey.COLOR]: {
                label: 'tc(color name)',
            },
            [ColumnKey.NAME]: {
                label: 'tc(name name)',
            },
            [ColumnKey.RANK]: {
                label: 'tc(rank name)',
            },
            [ColumnKey.PRICE]: {
                label: 'tc(price name)',
            },
            [ColumnKey.BASIC]: {
                label: 'tc(booking basic name)',
            },
            [ColumnKey.ROOM_NAME]: {
                label: 'tc(room name)',
            },
        };
    }

    get documents() {
        if (!this.ratesService.data) {
            return null;
        }

        if (this.ratesService.data instanceof RatesDocumentAllModel) {
            return null;
        }

        const documents = {
            main: this.ratesService.data,
        } as Record<string, RatesDocumentModel | null>;

        return documents;
    }

    get compset() {
        return this.compsetsService.currentCompset;
    }

    get isDocumentLoading() {
        return this.ratesService.isLoading;
    }

    get hotelColors() {
        if (!this.compset) { return null; }
        return this.hotelsService.getHotelsGraphColor(this.compset.id);
    }

    get hotelColorsRgb() {
        if (!this.compset) { return null; }
        return this.hotelsService.getHotelsGraphColorRgb(this.compset.id);
    }

    get documentSettings() {
        return this.documentFiltersService.settings;
    }

    get ratesSettings() {
        return {
            main: this.ratesService.settings,
        };
    }
}
