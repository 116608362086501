
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import Occupancy from '@/modules/common/components/ui-kit/occupancy/index.vue';
import Demand from '@/modules/common/components/ui-kit/demand/index.vue';
import { ASSESSMENT_TYPES } from '@/modules/common/constants';
import Day from '@/modules/common/types/day.type';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import type ICheckinDate from '@/modules/rates/interfaces/checkin-date.interface';
import type { HotelRooms } from '@/modules/common/interfaces';

import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';

import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ClusterDiLiteService, { ClusterDiLiteServiceS } from '@/modules/cluster/cluster-di-lite.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS } from '../../rates-price-history-common.service';

@Component({
    components: {
        CompsetScale,
        Demand,
        Occupancy,
    },
})
export default class RatesPriceHistoryStatistics extends Vue {
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @inject(ClusterRatesServiceS) private clusterRatesService!: ClusterRatesService;
    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(ClusterServiceS) private clusterService!: ClusterService;
    @inject(ClusterDiLiteServiceS) private clusterDiLiteService!: ClusterDiLiteService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(HelperServiceS) private helperService!: HelperService;

    @Prop({ type: Object, default: null })
    documents!: Record<string, RatesDocumentModel | RatesDocumentAllModel> | null;

    @Prop({ type: Number, required: true })
    tableDay!: number;

    @Prop({ type: String, required: true })
    priceShown!: PRICE_SHOWN;

    @Prop({ type: Boolean, required: true })
    skeleton!: boolean;

    @Prop({ type: Object, required: false })
    chartData!: any;

    formatPrice(price: number | null) {
        switch (price) {
            case null:
                return this.$tc('noData');
            case PRICE.SOLD_OUT:
                return this.$tc('soldOut');
            case PRICE.NA:
                return this.$tc('na');
            default:
                return this.currencySymbol(this.documents?.main.currency || '') + price;
        }
    }

    currencySymbol(rawCurrency: string) {
        return this.helperService.currencySymbol(rawCurrency) || rawCurrency;
    }

    get currentHotelId() {
        return +this.$route.params.hotelId!;
    }

    get day() {
        return +this.$route.params.day! as Day;
    }

    get compsetTypeLabel() {
        const compsetType = this.ratesPriceHistoryCommonService.compset?.type || COMPSET_TYPE.MEDIAN;
        return this.$tc(`compsetRate.${compsetType}`);
    }

    get occupancy() {
        if (this.tableDay === 0) {
            return this.ratesCommonService.getOccupancy(this.day, this.documents?.main as RatesDocumentModel);
        }

        return this.ratesPriceHistoryCommonService.getOccupancy();
    }

    get demand() {
        if (this.tableDay === 0) {
            return this.ratesCommonService.getDemand(this.day, this.documents?.main as RatesDocumentModel);
        }

        return this.ratesPriceHistoryCommonService.getDemand();
    }

    get dayColorAllChannels() {
        let assessment: ASSESSMENT_TYPES | null = null;

        const { compset } = this.ratesPriceHistoryCommonService;

        if (!compset || !this.highestPrice) {
            return { grey: true };
        }

        const diff = this.ratesCommonService.calculateDiffPercent(this.highestPrice, this.averagePrice);

        if (diff === null) {
            return { grey: true };
        }

        const diLiteThresholds = this.isDiLite && this.clusterDiLiteService.colorThresholds;
        assessment = this.ratesCommonService.getCardAssessment(diff, compset, diLiteThresholds || undefined);

        let color = 'grey';

        switch (assessment) {
            case ASSESSMENT_TYPES.GOOD:
                color = 'green';
                break;
            case ASSESSMENT_TYPES.NORMAL:
                color = 'yellow';
                break;
            case ASSESSMENT_TYPES.BAD:
                color = 'red';
                break;
            default: break;
        }

        return { [color]: true };
    }

    get dayColor() {
        if (this.compsetDiffData.isNA) {
            return { red: true };
        }

        if (this.compsetDiffData.isSoldOut || this.compsetDiffData.isNoData) {
            return { grey: true };
        }

        if (this.compsetDiffData.noComparisonData) {
            return {};
        }

        let assessment: ASSESSMENT_TYPES | null = null;

        if (this.isAllChannelsMode) {
            const { currentCompset } = this.compsetsService;
            const diff = this.highestPrice
                ? (this.highestPrice - this.averagePrice!) / this.averagePrice!
                : null;

            assessment = this.isDiLite
                ? this.clusterDiLiteService.getAssessment(this.currentHotelId, this.highestPrice!, this.averagePrice!)
                : this.ratesCommonService.getCardAssessment(diff!, currentCompset!);
        } else {
            assessment = this.compsetDiffData.assessment;
        }

        let color = 'grey';

        switch (assessment) {
            case ASSESSMENT_TYPES.GOOD:
                color = 'green';
                break;
            case ASSESSMENT_TYPES.NORMAL:
                color = 'yellow';
                break;
            case ASSESSMENT_TYPES.BAD:
                color = 'red';
                break;
            default: break;
        }

        return { [color]: true };
    }

    // Only for all channels
    get compsetDataType() {
        let isNoData = false;

        if (this.documents?.main.checkinDates
            && !Object.keys((this.documents.main as RatesDocumentAllModel).checkinDates![this.day] || {}).length
        ) {
            isNoData = true;
        }

        return {
            isNA: false,
            isSoldOut: !this.averagePrice,
            isNoData,
            noComparisonData: false,
        };
    }

    get averagePrice() {
        const filteredPrices = this.ratesCommonService
            .getFilteredPricesAllChannels(this.documents?.main as RatesDocumentAllModel, this.day, this.priceShown);

        if (!filteredPrices?.length) {
            return 0;
        }

        return Math.round(filteredPrices.reduce((acc, price) => acc + price, 0) / filteredPrices.length * 100) / 100;
    }

    get lowestPrice() {
        const filteredPrices = this.ratesCommonService
            .getFilteredPricesAllChannels(this.documents?.main as RatesDocumentAllModel, this.day, this.priceShown);

        if (!filteredPrices?.length) {
            return 0;
        }

        return filteredPrices[0];
    }

    get highestPrice() {
        const filteredPrices = this.ratesCommonService
            .getFilteredPricesAllChannels(this.documents?.main as RatesDocumentAllModel, this.day, this.priceShown);

        if (!filteredPrices?.length) {
            return 0;
        }

        return filteredPrices[filteredPrices.length - 1];
    }

    // Only for regular rates mode, not for all channels
    get compsetDiffData() {
        const compsetDiffData = {
            isNA: false,
            isSoldOut: false,
            isNoData: false,
            noComparisonData: false,
            value: 0,
            assessment: null,
            pax: null,
        } as {
            isNA: boolean,
            isSoldOut: boolean,
            isNoData: boolean;
            noComparisonData: boolean;
            value: number;
            assessment: ASSESSMENT_TYPES | null;
            pax?: number[] | null;
        };

        if (!this.documents
            || !this.ratesPriceHistoryCommonService.compset
            || !this.ratesPriceHistoryCommonService.documentSettings
        ) {
            compsetDiffData.isNoData = true;
            return compsetDiffData;
        }

        const documentKeys = Object.keys(this.documents);

        // Get prices for main hotel from each document
        const currentHotelPrices = documentKeys.reduce((acc, documentKey) => {
            if (!this.documents![documentKey]) {
                return {
                    ...acc,
                    [documentKey]: null,
                };
            }

            const { hotels } = (this.documents![documentKey].checkinDates![this.day] as ICheckinDate) || {};

            if (!hotels || !hotels[this.currentHotelId]) {
                return {
                    ...acc,
                    [documentKey]: null,
                };
            }

            const { rooms } = (this.documents![documentKey].checkinDates![this.day] as ICheckinDate).hotels[this.currentHotelId];
            const roomTypeId = Number(Object.keys(rooms)[0]);
            return {
                ...acc,
                [documentKey]: this.ratesCommonService.switchPrice(rooms[roomTypeId][0], this.priceShown),
            };
        }, {} as { [documentKey: string]: number | null });

        // Get compset prices (low/median/high) from each document
        const compsetPrices = documentKeys.reduce((acc, documentKey) => {
            if (!this.documents![documentKey]) {
                return {
                    ...acc,
                    [documentKey]: null,
                };
            }

            const { hotels } = (this.documents![documentKey].checkinDates![this.day] as ICheckinDate) || {};

            if (!hotels || !Object.keys(hotels).length) {
                return {
                    ...acc,
                    [documentKey]: null,
                };
            }

            const { type } = this.ratesPriceHistoryCommonService.compset!;
            const { competitors } = this.ratesPriceHistoryCommonService.documentSettings!;
            const competitorRooms = competitors
                .reduce((roomsAcc, hotelId) => ({
                    ...roomsAcc,
                    [hotelId]: hotels[hotelId]?.rooms[Number(Object.keys(hotels[hotelId].rooms)[0])][0] || null,
                }), {} as HotelRooms);
            const percent = this.ratesCommonService.getCompsetPrice(competitorRooms, type, this.priceShown);
            return {
                ...acc,
                [documentKey]: percent,
            };
        }, {} as { [documentKey: string]: number | null });

        // Calculate compset percentage diff between copmset prices and main hotel prices for each document
        const percentDiffs = documentKeys.reduce((acc, documentKey) => ({
            ...acc,
            [documentKey]: (currentHotelPrices[documentKey] && compsetPrices[documentKey])
                ? this.ratesCommonService.calculateDiffPercent(currentHotelPrices[documentKey]!, compsetPrices[documentKey]!)
                : null,
        }), {} as { [documentKey: string]: number | null });

        const assessmentThresholds = (this.$route.name?.includes('cluster.di-lite') && this.clusterDiLiteService.colorThresholds)
            || (this.$route.name?.includes('cluster') && this.clusterRatesService.colorThresholds);

        // Format output
        if (documentKeys.length === 1 && percentDiffs.main !== null && currentHotelPrices.main && currentHotelPrices.main > 0) {
            compsetDiffData.value = Math.round(percentDiffs.main * 100);
            compsetDiffData.assessment = this.ratesCommonService.getCardAssessment(
                percentDiffs.main,
                this.ratesPriceHistoryCommonService.compset,
                assessmentThresholds || undefined,
            );
            return compsetDiffData;
        }

        if (documentKeys.length === 1 && percentDiffs.main === null && currentHotelPrices.main && currentHotelPrices.main > 0) {
            compsetDiffData.value = 0;
            return compsetDiffData;
        }

        if (documentKeys.length > 1) {
            const mainPrice = currentHotelPrices.main;

            if (mainPrice === null || mainPrice < 0) {
                compsetDiffData.noComparisonData = true;
                return compsetDiffData;
            }

            const biggestPrice = documentKeys.reduce((acc, key) => {
                if (key === 'main' || !currentHotelPrices[key]) {
                    return acc;
                }

                const diff = Math.abs(mainPrice - currentHotelPrices[key]!);
                return (acc === -1 || diff > Math.abs(mainPrice - acc)) ? currentHotelPrices[key]! : acc;
            }, -1);

            if (biggestPrice === null || biggestPrice < 0) {
                compsetDiffData.noComparisonData = true;
                return compsetDiffData;
            }

            const percent = (mainPrice - biggestPrice) / biggestPrice;

            compsetDiffData.value = Math.round(percent * 100);
            compsetDiffData.assessment = this.ratesCommonService.getCardAssessment(
                percent,
                this.ratesPriceHistoryCommonService.compset,
                assessmentThresholds || undefined,
            );
            return compsetDiffData;
        }

        if (currentHotelPrices.main === null && compsetPrices.main !== null || currentHotelPrices.main === -1) {
            compsetDiffData.isNA = true;
            return compsetDiffData;
        }

        if (currentHotelPrices.main === 0) {
            compsetDiffData.isSoldOut = true;
            compsetDiffData.pax = this.ratesCommonService
                .getCheckinDay(this.day, this.documents.main)?.hotels[this.currentHotelId]?.pax;
            return compsetDiffData;
        }

        compsetDiffData.isNoData = true;
        return compsetDiffData;
    }

    get isAllChannelsMode() {
        return this.documents?.main.providerName === 'all';
    }

    get isAnalysisPage() {
        return this.$route.path.includes('analysis');
    }

    get isDiLite() {
        return this.$route.name!.includes('.di-lite');
    }

    get isChartEmpty() {
        return this.chartData?.datasets
            ? this.chartData.datasets.every((dataset: any) => !dataset.data?.filter(Boolean).length)
            : true;
    }
}
