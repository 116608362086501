
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';

import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import JsonViewer from '@/modules/common/components/ui-kit/json-viewer.vue';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import type Day from '@/modules/common/types/day.type';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import PopupEvents from '@/modules/events/components/popup-events.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import type { TrendFilters, TrendsDocument } from '../../types';
import Filters from './filters.vue';
// import Chart from './chart.vue';
import Table from './table.vue';
import Tooltip from './tooltip.vue';
import Statistics from './statistics.vue';
import { ColumnKey } from '../../constants';

@Component({
    components: {
        CustomGraph,
        CompsetScale,
        CustomCheckbox,
        PopupEvents,
        ModalWrapper,
        LoaderWrapper,
        TogglePrices,
        JsonViewer,
        PopupEventsContainer,
        RatesDayScanBtn,

        Filters,
        // Chart,
        Table,
        Tooltip,
        Statistics,
    },
})
export default class DayPopup extends Vue {
    /** Pure component. Only for internal use in other popups, don't use it directly. Don't inject services */

    @Prop({ type: Object, default: null })
    trendsDocument!: TrendsDocument | null;

    @Prop({ type: Number })
    trendIndex!: number;

    @Prop({ type: Object, default: null })
    filters!: TrendFilters | null;

    @Prop({ type: Date })
    date!: Date;

    @Prop({ type: Boolean, default: false })
    isNoChart!: boolean;

    @Prop({ type: Boolean, default: false })
    isSuperadmin!: boolean;

    @Prop({ type: String })
    priceShown!: PRICE_SHOWN;

    @Prop({ type: Object })
    tableColumns!: Record<ColumnKey, {
        label: string;
    }>;

    @Prop({ type: Object })
    tooltipColumns!: Record<ColumnKey, {
        label: string;
    }>;

    hiddenGraphs: { [key: string]: boolean } = {};
    showRawData = false;

    tooltipFocusElement: HTMLElement | null = null;
    tooltipDay: Day | null = null;

    // setTooltipElement(el: HTMLElement) {
    //     this.tooltipFocusElement = el;
    // }

    // setTooltipDay(label: string) {
    //     if (label === ZERO_DAY) {
    //         this.tooltipDay = 0 as Day;
    //         return;
    //     }

    //     this.tooltipDay = (label
    //         ? parseInt(label, 10)
    //         : null) as Day;
    // }

    // setDay(label: string) {
    //     const dayIndex = parseInt(label.replace('-', '').trim(), 10);
    //     this.ratesPriceHistoryCommonService.setTableDay(dayIndex);
    // }
}
